import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import { ElMessage } from 'element-plus';
import 'element-plus/dist/index.css';
// import '../styles/element/index.scss';

export default {
  ElementPlus,
  ElementPlusIconsVue,
  ElMessage
};
