// import locale from 'element-plus/lib/locales';
import { createI18n } from 'vue-i18n'
import messages from './langs'

//从localStorage中拿到用户的语言选择，如果没有，那默认中文。
const i18n = createI18n({
    locale: localStorage.lang || 'cn',
    messages,
});
// locale.i18n((key, value) => i18n.t(key, value)); //为了实现element插件的多语言切换

export default i18n
