import { createApp } from 'vue'
import App from './App.vue'

// import 'element-plus/theme-chalk/src/message.scss';
import './iconfont.css';
// import 'element-plus/dist/index.css'

// axios 负责统一拦截处理 ResultDTO，fly 负责处理不需要拦截的请求
import axios from 'axios';
import flyio from 'flyio';
import router from "./router";
import store from "./store";
import common from "./common";
import i18n from "./i18n/i18n";
import element from "./plugins/element";

// let baseURL = "http://139.224.83.134:7700";
let baseURL = process.env.VUE_APP_API_BASE_URL;
// let baseURL = '/api';
console.log(baseURL);
let timeout = 10000;
/* ******* axios config ******* */
axios.defaults.baseURL = baseURL;
axios.defaults.timeout = timeout;
/* ******* fly.io config ******* */
flyio.config.baseURL = baseURL;
flyio.config.timeout = timeout;

const app = createApp(App);
app.config.globalProperties = {
  common: common,
  axios: axios,
  flyio: flyio,
  i18n: i18n,
};
app.config.productionTip = false;

app
  .use(element.ElementPlus)
  .use(i18n)
  .use(router)
  .use(store);

for (const [key, component] of Object.entries(element.ElementPlusIconsVue)) {
  app.component(key, component)
}

//请求发送拦截，没有 appId 要求重新 "登录"
axios.interceptors.request.use((request) => {
  let url = request.url;
  let isListAppInfo = url.search("/appInfo/list") !== -1;
  let isAppRegister = url.search("/appInfo/save") !== -1;
  let isUserRegister = url.search("/user/save") !== -1;
  let isAssertAppInfo = url.search("/appInfo/assert") !== -1;

  if (isListAppInfo || isAppRegister || isUserRegister || isAssertAppInfo) {
    return request;
  }

  let appId = store.state.appInfo.id;
  if (!appId) {
    router.push("/");
    // remove no appId warn due to too much user report this is a bug...
    return Promise.reject();
  }
  return request;

}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// 请求返回拦截，封装公共处理逻辑
axios.interceptors.response.use((response) => {
  if (!response.data) {
    if (response.message) {
      element.ElMessage.error(response.message);
    }
    return Promise.reject(response.message);
  }
  if (response.data.success === true) {
    if (response.data.data) {
      return response.data.data;
    }
    return response.data;
  }
  element.ElMessage.warning("ERROR：" + response.data.message);
  return Promise.reject(response.data);
}, (error) => {
  if (error) {
    element.ElMessage.error(error);
  }
  return Promise.reject(error);
});

app.mount('#app');

export default baseURL;
